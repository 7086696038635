import styled, { css } from 'styled-components';

import Text from '../ui/Text';
import { below } from '../../styles/libs';

export default styled.div`
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-top: 0.5rem;

  ${({ noMargin }) => noMargin && css`
    margin: 0;
  `}
  ${({ reviewItem }) => reviewItem && css`
    font-size: 1rem;
  `}
`;

export const Label = styled(Text)`
  margin-right: 0.5rem;
  min-width: 4rem;
  // defaults to 0 0 4rem (from BookingSummaryItem.jsx prop)
  flex: ${({ flex }) => flex};


  ${({ reviewItem }) => reviewItem && css`
    min-width: 7rem;
    max-width: 100%;
  `}

  ${({ isResponsive }) => isResponsive && css`
    flex: 0 0 8rem;
    min-width: 8rem;
  `}

  ${({ isResponsive, theme }) => isResponsive && below(theme.TABLET)`
    flex: 0 0 4rem;
    min-width: 4rem;
  `};
`;

export const Value = styled(Text)`
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
`;

export const DividerWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.COLOR_GRAY_LIGHT};
  margin-top: 1rem;
  padding-top: 1rem;
`;

export const PriceContainer = styled.div`
  margin-left: auto;
  padding-left: 0.5rem;
`;
