import { mapPartyType, mapPartyTypes } from '../../config/personType';

/**
 * Converts a singular party member type to it's plural form using our FE config
 * @param {'Adult' | 'Child' | 'Infant'} key
 * @returns {'Adults' | 'Children' | 'Infants'}
 */
const mapSingularMemberToPlural = (key) => {
  const personTypeId = Object.values(mapPartyType).findIndex((type) => type === key);
  return mapPartyTypes[personTypeId] ?? key;
};

export function stringifyPartyMembers(
  party,
  partyMemberTypes,
  partyMemberReference,
  isAmend,
  options = {
    withPlurals: false,
  },
) {
  const partyObj = {};
  let partyCopy = [...party];
  if (!isAmend && partyMemberReference?.length) {
    partyCopy = partyCopy.filter(({ personId }) => partyMemberReference.includes(personId));
  }

  partyCopy.forEach(({ type }) => {
    const partyMemberType = partyMemberTypes.find(({ key }) => key === type) || {};
    const partyMemberTypeValue = partyMemberType.value;

    partyObj[partyMemberTypeValue] = partyObj[partyMemberTypeValue] ?
      partyObj[partyMemberTypeValue] + 1 :
      1;
  });

  // eg. 'Adult x 1, Child x 2, Infant x 1'
  if (!Object.keys(partyObj).length) return 'None';
  return Object.keys(partyObj).map(key => `${options.withPlurals ? mapSingularMemberToPlural(key) : key} x ${partyObj[key]}`).join(', ');
}

export function groupGuests(guests) {
  return guests.reduce((grouped, guest) => {
    const groupedCopy = { ...grouped };
    const key = `${guest.stayStart}-${guest.stayEnd}`;
    if (groupedCopy[key]) {
      groupedCopy[key].push(guest);
    } else {
      groupedCopy[key] = [guest];
    }
    return groupedCopy;
  }, {});
}

export default { stringifyPartyMembers };
