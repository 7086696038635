import moment from 'moment';
import { isWithinRange, isBefore } from 'date-fns';
import PLACE_EVENT_TYPES from '../../config/eventTypes';
import { differenceInDays } from '../dates';

function checkWithinRange(d, fromDate, toDate) {
  if (isBefore(toDate, fromDate)) return false;
  return isWithinRange(d, fromDate, toDate);
}

export function checkOpen(openDates = [], ...rest) {
  if (!rest[0] || !rest[1] || !openDates.length) return true;

  const isOpen = openDates
    .map(({ fromDate, toDate }) => !rest
      .map(d => checkWithinRange(d, fromDate, toDate))
      .includes(false))
    .includes(true);

  return isOpen;
}

export function validateDates(quote = {}) {
  const { start, end } = quote;

  if (!start) return new Error('Please provide the dates for your stay.');
  if (!end) return new Error('Please provide a return date.');

  return null;
}

/**
 * @summary Validate the length of stay with pitch rules
 * @param {Object} pitch
 * @param {Object} quote
 * @returns {string} The error message or null if valid
 */
export function validateStay(pitch, quote, rules = {}) {
  const { start, end } = quote;

  if (!checkOpen(rules.openDates, start, end)) return new Error('Campsite is closed during the requested dates.');

  if (!pitch) return null;

  const { maxNights, minNights } = pitch;

  const lengthOfStay = Math.abs(differenceInDays(start, end)) + 1;

  if ((lengthOfStay < minNights) && !validateDates(quote)) return new Error('Does not meet minimum length of stay requirements.');
  if ((lengthOfStay > maxNights) && !validateDates(quote)) return new Error('Exceeds max length of stay requirements.');

  return null;
}

export function formatHourToTime(hour) {
  return moment(`${hour}`, 'H').format('H:mm');
}

export function formatHourToRange(hour, interval = 1) {
  if (hour === undefined) {
    return '';
  }
  return `${formatHourToTime(hour)} - ${formatHourToTime(hour + interval)}`;
}

export function createArrivalTimeOptions(fromTime, toTime) {
  const options = [];
  if (fromTime === undefined || toTime === undefined) {
    return options;
  }
  let start = fromTime;
  let end = fromTime + 1;

  while (end <= toTime) {
    const value = formatHourToRange(start);
    options.push({
      key: start,
      value,
    });
    start += 1;
    end += 1;
  }
  return options;
}

export const getErrorElement = (
  { attribute, className } = {
    attribute: 'data-error',
    className: 'formError',
  },
) => {
  const htmlElements = Array.from(document.querySelectorAll(`[${attribute}]`));
  const formErrorElements = Array.from(
    document.querySelectorAll(`.${className}`),
  );
  const element = [...htmlElements, ...formErrorElements].find(
    (ele) => ele.attributes[attribute]?.value === 'true' ||
      ele.classList?.contains(className),
  );
  return element;
};

// Generate a default camping form state using touring state
export const generateCampingState = (touringState, partyMembers) => ({
  ...touringState,
  arrivalTime: undefined,
  eventType: PLACE_EVENT_TYPES.CAMPING.id,
  extras: [],
  guests: [],
  id: 0,
  maxDate: touringState.end,
  minDate: touringState.start,
  offers: [],
  outfit: {},
  parentPitchId: touringState.id || undefined,
  partyMembers,
  pitchTypeId: undefined,
  pitchName: '',
  specReq: [],
  supplements: [],
});

export const canAddMoreCampingPitches = (payload, payloads, isMember) => {
  const MAX_CAMPING_PITCHES = isMember ? 8 : 1;
  const containsNewCampingPitches = payloads.camping?.some(campingPayload => !campingPayload.id);
  const isEventTypeValid = payload.eventType === PLACE_EVENT_TYPES.CAMPING.id ||
    (payload.eventType === PLACE_EVENT_TYPES.TOURING.id && !containsNewCampingPitches);
  return !!payloads.camping.length && isEventTypeValid &&
    payloads.camping.length < MAX_CAMPING_PITCHES;
};
