import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import BookingSummaryItemStyled, {
  Label, Value, Row, Wrapper,
  PriceContainer,
} from './BookingSummaryItem.style';
import { Flex } from '../ui/Grid/Row.style';
import { formatPriceWithCurrency } from '../../lib/format';

function BookingSummaryItem({
  label, value, flex, className, noMargin, reviewItem, isResponsive,
}) {
  if (!value) return null;
  const values = Array.isArray(value) ? value : [value];
  const filteredValues = values.filter(({ name }) => name != null);

  return (
    <BookingSummaryItemStyled
      className={className}
      noMargin={noMargin}
      reviewItem={reviewItem}
    >
      <Label reviewItem={reviewItem} flex={flex} isResponsive={isResponsive}>{label}{label ? ':' : ''}</Label>
      <Wrapper>
        {!filteredValues.length &&
          <Value>
            <b data-testid="none-text">None</b>
          </Value>
        }

        {!!filteredValues.length && filteredValues
          .sort((a, b) => a.name?.localeCompare(b.name))
          .map(bookingItem => (
            <Row key={uuid()}>
              <Value reviewItem={reviewItem}>
                <Flex>
                  <div>
                    <b data-testid="name-text" key={bookingItem}>{bookingItem.name}</b>
                    {bookingItem.description && <span>{bookingItem.description}</span>}
                  </div>
                  {bookingItem.price > 0 &&
                    <PriceContainer>
                      {bookingItem.price && <b>{formatPriceWithCurrency(bookingItem.price)}</b>}
                    </PriceContainer>
                  }
                </Flex>
              </Value>
            </Row>
          ))}
      </Wrapper>
    </BookingSummaryItemStyled>
  );
}

BookingSummaryItem.propTypes = {
  flex: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  noMargin: PropTypes.bool,
  reviewItem: PropTypes.bool,
  isResponsive: PropTypes.bool,
};

BookingSummaryItem.defaultProps = {
  className: '',
  flex: '0 0 4rem',
  label: '',
  value: null,
  noMargin: false,
  reviewItem: false,
  isResponsive: false,
};

export default BookingSummaryItem;
